<template>
  <header>
    <div class="header">
      <router-link to="/" title="Home"><h2>PAREENA.PI</h2></router-link>
      <p class="fa fa-bars burger-icon" @click="displayMenu()"></p>
      <div class="burger-menu">
        <div class="icons">
          <div class="icons-left">
            <a
              href="https://www.instagram.com/pareena.pi/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com/pareena.nogues"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-facebook"></i>
            </a>
          </div>
          <i class="fa fa-moon-o" id="switch-mode" @click="switchMode()"></i>
        </div>
        <nav>
          <router-link to="/produit" title="Boutique en ligne">
            boutique en ligne
          </router-link>
          <router-link to="/histoire-concept" title="Histoire - concept">
            histoire - concept
          </router-link>
          <router-link to="/contact" title="Contact"> contact </router-link>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  methods: {
    switchMode() {
      document.body.classList.toggle("dark-mode");
      let mode = document.getElementById("switch-mode");
      if (mode.classList.contains("fa-moon-o")) {
        mode.setAttribute("class", "fa fa-sun-o");
      } else {
        mode.setAttribute("class", "fa fa-moon-o");
      }
    },
    displayMenu() {
      let menu = document.getElementsByClassName("header")[0];
      if (menu.style.height == "28px" || menu.style.height == "") {
        menu.style.height = "220px";
      } else {
        menu.style.height = "28px";
      }
    },
  },
};
</script>
