<template>
  <footer>
    <div class="footer">
      <p class="footer-text">
        DÉCOUVREZ LA NOUVELLE MARQUE « PAREENA PI », DEPUIS 2021.
      </p>
      <p class="footer-text">
        UN CONCEPT QUI NOUS RASSEMBLE AVEC DES BIJOUX QUI NOUS RESSEMBLENT
      </p>
      <!-- <div class="footer-div">
        <router-link to="/" title="Faq">Faq</router-link>
        <router-link to="/" title="Mentions légales"
          >Mentions légales</router-link
        >
        <router-link to="/" title="Newsletter">Newsletter</router-link>
      </div> -->
      <p>© Edouard Salembier - {{ year }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "HelloWorld",
  data: function () {
    return {
      year: "",
    };
  },
  mounted() {
    const d = new Date();
    this.year = d.getFullYear();
  },
};
</script>
