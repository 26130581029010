<template>
  <div class="homepage">
    <div class="homepage-img">
      <img src="../assets/homepagePareena.png" alt="Pareena" class="pareena" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
